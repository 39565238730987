import React from "react"
/* import { Router } from '@reach/router'; */
import { login, isAuthenticated, getProfile } from "../../utils/auth"
import RequestDemoDA from "./requestDemo"

const AuthenticatedRoute = () => {
  if (!isAuthenticated()) {
    login()
    return (
      <div className="my-5 w-100 text-center">
        <img src="/images/loader.svg" alt="loading" />
        <p>Redirecting to login...</p>
      </div>
    )
  }

  return <RequestDemoDA />
}

export default AuthenticatedRoute
